<template>
  <div class="warp">
    <common-header></common-header>
    <div class="selection-box">
      <div class="item" @click="openPicker()">
        <span>{{ startTime }}</span>
        <i class="icon-black-arrow"></i>
      </div>
      <div class="item" @click="commodityType = true">
        <span>{{ commodityObj ? commodityObj.name : "" }}</span>
        <i class="icon-black-arrow"></i>
      </div>
    </div>
    <table
      class="table"
      v-if="lists.length > 0"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-immediate-check="false"
      infinite-scroll-distance="10"
    >
      <tr>
        <th>时间</th>
        <th>类型</th>
        <!-- <th>订单</th> -->
        <th>金额</th>
      </tr>
      <tr v-for="(item, index) in lists" :key="index">
        <td>{{ item.createTime.substring(8, 10) }}号</td>
        <td>{{ item.description }}</td>
        <!-- <td>{{item.amount}}</td> -->
        <td>
          {{ item.direction ? "-" : item.amount > 0 ? "+" : ""
          }}{{ item.amount }}
        </td>
      </tr>
    </table>

    <no-data v-if="nodata" style="margin-top:1rem"></no-data>

    <mt-datetime-picker
      ref="picker"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      :endDate="endDate"
      @confirm="handleConfirm"
      v-model="timeStartValue"
    >
    </mt-datetime-picker>

    <mt-popup class="w100" v-model="commodityType" position="bottom">
      <div class="select-popup-btn fix">
        <span class="btn fl" @click="commodityType = false">取消</span>
        <span class="btn fr" @click="modifyCommodity">确定</span>
      </div>
      <mt-picker
        :slots="commoditySlots"
        @change="commodityChange"
        value-key="name"
      ></mt-picker>
    </mt-popup>

    <div class="bottom-plac">
      <div class="fixed-bottom">
        <div class="left">
          当前余额：{{ balance >= 0 ? "" : "-" }}￥{{ Math.abs(balance) }}
        </div>
        <div class="recharge" @click="chongzhiClick">充值</div>
      </div>
    </div>

    <mt-popup :closeOnClickModal="isClickHide" class="w100" v-model="chongzhi" position="center">
      <div class="mint-msgbox">
        <div class="mint-msgbox-content">
          <div class="mint-msgbox-message" style="color: #333">余额充值</div>
          <div class="mint-msgbox-input">
            <input style="text-align:center" placeholder="请输入要充值的金额" type="number" inputmode="decimal" v-model="chongzhiMN" />
            <div class="mint-msgbox-errormsg" v-if="errormsg">请输入要充值的金额</div>
          </div>
        </div>
        <div class="mint-msgbox-btns">
          <button class="mint-msgbox-btn mint-msgbox-cancel " style="" @click="chongzhi= false">
            取消
          </button>
          <button class="mint-msgbox-btn mint-msgbox-confirm " @click="recharge">确定</button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { DateFormat, onBridgeReadyStart, isWeiXin } from "@/utils/common";
export default {
  data() {
    return {
      isClickHide: false,
      chongzhi: false,
      chongzhiMN: '',
      commodityType: false,
      nodata: false,
      errormsg: false,
      loading: false,
      commodityObj: "",
      commoditySlots: [
        {
          flex: 1,
          defaultIndex:4,
          values: [
            {
              name: "订单取消/退款",
              id: 7,
            },
            {
              name: "商品购买",
              id: 8,
            },
            {
              name: "退押金",
              id: 9,
            },
            {
              name: "余额充值",
              id: 10,
            },
            {
              name: "订单修改补差价",
              id: 11,
            },
            {
              name: "订单退款返差价",
              id: 12,
            },
            {
              name: "系统赠予/扣除",
              id: 13,
            },
            {
              name: "推广员赠送",
              id: 14,
            },
          ],
          className: "commodity-slots-class",
          textAlign: "center",
        },
      ],
      startTime: DateFormat(new Date()).substring(0, 7),
      endDate: new Date(),
      timeStartValue: new Date(),
      rightTxt: "",
      lists: [],
      pages: 1,
      pageNum: 1,
      nomore: false,
      balance: 0,
    };
  },
  mounted() {
    this.getBal();
    this.init({
      srcType: this.commodityObj.id,
      queryDate: this.startTime,
    });
  },
  methods: {
    showLoadTxt(type) {
      if (type == 'show') {
        this.$Indicator.open({
          text: "加载中...",
          spinnerType: "snake"
        });
      } else {
        this.$Indicator.close();
      }
    },
    // 获取余额
    getBal() {
      this.userCouponsCount().then((res) => {
        this.balance = res.data.balance;
      });
    },
    chongzhiClick() {
      if (!isWeiXin()) {
        this.$toast("请在微信中打开页面支付！");
        return false;
      }
      this.chongzhi = true;
    },
    // 充值
    recharge() {
      
      let value = this.chongzhiMN;
      if (value > 0) {
        this.postBalancePay({
          amount: value,
        }).then((res) => {
          onBridgeReadyStart(
            JSON.parse(res.data.payParam),
            (res3) => {
              this.getBalancePay({
                tradeNo: res.data.tradeNo,
              }).then((status) => {
                var st = status.data.status;
                if (st == "SUCCESS") {
                  this.$toast("支付成功");
                  this.getBal();
                } else if (st == "NOTPAY") {
                  this.$toast("未支付");
                }
              });
            },
            () => {
              this.$toast("取消支付");
            }
          );
        });
      } else {
        this.errormsg = true;
      }
    },
    loadMore() {
      this.loading = true;
      let pageNum = this.pageNum + 1;
      if (pageNum > this.pages) {
        this.nomore = true;
      } else {
        this.init({
          srcType: this.commodityObj.id,
          queryDate: this.startTime,
          pageNum: pageNum,
          add: "add",
        });
      }
    },
    init(obj) {
      this.showLoadTxt('show');
      this.getTransactionList({
        srcType: obj.srcType,
        queryDate: obj.queryDate,
        amountType: 1,
        balanceType: 1,
        pageNum: obj.pageNum || 1,
      }).then((res) => {
        if (obj.add) {
          this.lists = this.lists.concat(res.data.list);
          this.pageNum = res.data.pageNum;
        } else {
          this.lists = res.data.list;
          this.pages = res.data.pages;
        }
        this.nodata = this.lists.length > 0 ? false : true;
        this.showLoadTxt('hide');
      });
    },
    ...mapActions("account", [
      "getTransactionList",
      "postBalancePay",
      "getBalancePay",
      "userCouponsCount",
    ]),
    openPicker(i) {
      this.$refs.picker.open();
      let a = document.getElementsByClassName("picker-slot");
      for (let i = 0; i < a.length; i++) {
        if (i == 2) {
          a[i].style.display = "none";
        }
      }
    },
    commodityChange(picker, values) {
      this.commodityObj = values[0] ? values[0] : "";
    },
    modifyCommodity() {
      this.commodityType = false;
      this.init({
        srcType: this.commodityObj.id,
        queryDate: this.startTime,
      });
    },
    handleConfirm(e) {
      this.startTime = DateFormat(e).substring(0, 7);
      this.init({
        srcType: this.commodityObj.id,
        queryDate: this.startTime,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bottom-plac {
  height: 1rem;
  margin-top: .5rem;
}
.fixed-bottom {
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  padding: 0 0.2rem;
  border-top: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .recharge {
    width: 2.5rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 1rem;
    background: rgb(255, 114, 65);
    color: #fff;
    text-align: center;
  }
}
.table {
  width: 100%;
  margin-top: 0.4rem;
  text-align: center;
  th {
    font-size: 0.3rem;
  }
  td {
    font-size: 0.28rem;
  }
  td,
  th {
    border: 1px solid #f5f5f5;
    padding: 0.2rem 0;
  }
}
.selection-box {
  padding-top: 0.4rem;
  display: flex;
  .item {
    border: 1px solid #f5f5f5;
    padding: 0.2rem;
    flex: 1;
    margin-right: 0.2rem;
    border-radius: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
